<template>
    <custom-dialog :visible.sync="visible"  width="1124px" @close="colseDialog" @submit="submit" title="房间导入" >
        <custom-tables
        :data="tableData"
        isadd="false"
        noexport="false"
        noimport="false"
        isdel="true"
        title="房间列表"
        @selectionChange="selectionChange"
       
        
       >
      <el-table-column type="selection" align="center" width="55"></el-table-column>
      <el-table-column prop="roomNo" label="房间编号" width="180"></el-table-column>
      <el-table-column prop="roomType" label="房间类别">
        <template slot-scope="scope">
            <span v-if="scope.row.roomType==1">单人间</span>
            <span v-if="scope.row.roomType==2">双人间</span>
        </template>
      </el-table-column>
      <el-table-column prop="roomFloor" label="楼层"></el-table-column>
      <el-table-column prop="hotelName" label="所属酒店"></el-table-column>
      <!-- <el-table-column prop="roomStatus" label="状态"></el-table-column> -->
      <el-table-column prop="roomBedCount" label="床类数"></el-table-column>
      <!-- <el-table-column prop="roomBedUse" label="已分配数"></el-table-column> -->
      <!-- <el-table-column prop="createTime" label="添加时间"></el-table-column> -->
      <el-table-column label="操作" align="center" width="100">
        <template slot-scope="scope">
          <span class=" btn-icon" @click="editItem(scope.row)">编辑</span>
          <span class=" btn-icon" @click="delItem(scope.row)">删除</span>
        </template>
      </el-table-column>
    </custom-tables>
    <ImrortAdd  :title="dialogTitle"
                      :item="item"
                      :visible.sync="showDialog"
                      @close="showDialog = false"
                      @done="editDone"></ImrortAdd>
    </custom-dialog>
  </template>
  
  <script>
  import CustomDialog from "../../common/CustomDialog";
  import basic from "../../../api/modules/basic";
  import {organizationCascader} from "../../../utils/tool";
  import accommodation from "../../../api/modules/accommodation";
  import CustomTables from "../../../components/common/CustomTables";
  import ImrortAdd from '../../../components/page/accommodation/ImrortAdd.vue'
  export default {
    name: "EditAssignmentDialog",
    components: { CustomDialog,CustomTables,ImrortAdd},
    props: ['visible', 'title','item2',  'type'],
    data() {
      return {
        editForm: {},
        rules: {},
        searchForm: {},
        searchData: [],
        orgaIdArr:[],
        copanyListInfo: [],
        hotelList:[],
        roomInfoList:[],
        userId: undefined,
        tableData:[],
        dialogTitle:'',
        showDialog:false,
        item:''
      }
    },
    mounted() {
      this.getCopanyInfo()
      this.getHotelInfo()
      
    },
    watch:{
    item2(newVal,oldVal){
      if(newVal){
        console.log(newVal);
        this.tableData=newVal
      }
    }
  },
    methods: {
      getHotelInfo(){
        accommodation.getHotelList().then(res =>{
          this.hotelList = res.data
        })
      },
      editDone() {
      // 编辑成功返回
      this.showDialog = false;
      this.getInfo();
    },
    getInfo(){
        basic.redisList().then(res =>{
        console.log(res,'00');
        this.tableData=res.data

      })
    },
      getCopanyInfo() {
        basic.getOrganizationTree({orgaPid: 0}).then((res) => {
          // 递归遍历 生成 {value, label, children}
          let data = organizationCascader(res.data, "noProject");
          this.copanyListInfo = data;
        });
      },
      colseDialog() {
        this.$emit('close')
        this.cleanForm()
      },
      submit() {
      
        basic.roomData().then(res => {
                this.$message({
                  message: '新增成功',
                  type: 'success'
                });
                this.$emit('done')
                this.cleanForm()
              })
      },
      cleanForm(){
        this.editForm = {}
        this.searchData = []
        this.searchForm = {}
        this.orgaIdArr = []
      },
      searchPerson() {
        accommodation.getAllotUserInfo(this.searchForm).then(res =>{
          this.searchData = res.data
        })
      },
      cascaderChange(val) {
        console.log(val);
        let valLen = val.length;
        this.searchForm.orgaPathIds = val[valLen - 1];
      },
      hotelChange(val) {
        this.findRoomInfo()
      },
      findRoomInfo() {
        let params = {
          hotelId: this.editForm.hotelId,
          roomFloor: this.editForm.roomFloor,
          type: this.type
        }
        accommodation.getAllotRoom(params).then(res => {
          this.roomInfoList = res.data
          // let roomInfoObj = {}
          // res.data.forEach(item =>{
          //   if(roomInfoObj[item.roomNo]){
          //
          //   }
          // })
        })
      },
      tableChange(val){
        console.log('val', val)
        this.userId = val.userId
      },
      selectionChange(){},
      editItem(row){
        this.showDialog=true
        this.item = row
      },
      delItem(row){
        console.log(row);
        this.$confirm(`此操作将永久删除该房间信息, 是否继续?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        basic.deleteFollowUser(row.roomNo).then((res) => {
          this.$message({
            message: "删除成功",
            type: "success",
          });
          this.getInfo();
        });
      }).catch(() => {
        this.$message({
          type: "info",
          message: "已取消删除",
        });
      });
      }
    }
  }
  </script>
  
  <style scoped lang="less">
  .asssign-box {
    border: 1px solid #f0f0f0;
    border-radius: 5px;
    //padding: 10px;
    .title {
      font-size: 16px;
      border-bottom: 1px solid #f0f0f0;
      padding: 10px;
      color: #000000;
    }
  
    .box-body {
      padding: 10px;
      min-height: 500px;
    }
  }
  
  </style>