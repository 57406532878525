<template>
  <div class="pageMain">
    <search-head @reset="headReset" @search="headSearch">
      <el-form :inline="true" :model="searchForm">
        <el-form-item label="所属酒店">
          <el-select v-model="searchForm.hotelId" size="small" class="block-select" placeholder="请选择所属酒店">
            <el-option
                v-for="item in hotelList"
                :key="item.hotelId"
                :label="item.hotelName"
                :value="item.hotelId">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="楼层">
          <el-input
              size="small"
              clearable
              v-model="searchForm.roomFloor"
              placeholder="请输入楼层"
          ></el-input>
        </el-form-item>
        <el-form-item label="房间编号">
          <el-input
              size="small"
              clearable
              v-model="searchForm.roomNo"
              placeholder="请输入房间编号"
          ></el-input>
        </el-form-item>
      </el-form>
    </search-head>
    <custom-tables
        :data="tableData"
        :total="total"
        isdel="true"
        title="房间列表"
        @selectionChange="selectionChange"
        @handleCurrentChange="handleCurrentChange"
        @handleSizeChange="handleSizeChange"
      
        @tableExport="tableExport"
        @tableImport="tableImport"
        @tableDel="tableDel"
        @tableGetImport="tableGetImport"
        
        @tableAdd="tableAdd">
      <el-table-column type="selection" align="center" width="55"></el-table-column>
      <el-table-column prop="roomNo" label="房间编号" width="180"></el-table-column>
      <el-table-column prop="roomTypeName" label="房间类别"></el-table-column>
      <el-table-column prop="roomFloor" label="楼层"></el-table-column>
      <el-table-column prop="hotelName" label="所属酒店"></el-table-column>
      <el-table-column prop="roomStatusName" label="状态"></el-table-column>
      <el-table-column prop="roomBedCount" label="床类数"></el-table-column>
      <el-table-column prop="roomBedUse" label="已分配数"></el-table-column>
      <el-table-column prop="createTime" label="添加时间"></el-table-column>
      <el-table-column label="操作" align="center" width="100">
        <template slot-scope="scope">
          <span class=" btn-icon" @click="editItem(scope.row)">详情</span>
          <span class=" btn-icon" @click="delItem(scope.row)">删除</span>
        </template>
      </el-table-column>
    </custom-tables>
    <edit-room-dialog :visible.sync="showDialog"
                      :title="dialogTitle"
                      :item="item"
                      :disabled="isDetail"
                      @close="showDialog = false"
                      @done="editDone">
    </edit-room-dialog>
    <ImportEdit :visible.sync="showDialog2"
                      :title="dialogTitle2"
                      :item2="item2"
                      :disabled="isDetail"
                      @close="showDialog2 = false"
                      @done="editDone"></ImportEdit>
  </div>
</template>

<script>
import accommodation from "../../../api/modules/accommodation";
import basic from "../../../api/modules/basic";
import CustomTables from "../../../components/common/CustomTables";
import SearchHead from "../../../components/common/SearchHead";
import {downloadFile} from "../../../utils/tool";
import EditRoomDialog from "../../../components/page/accommodation/EditRoomDialog";
import {roomTypeObj, roomStatusObj} from "../../../utils/hotelType";
import ImportEdit from '../../../components/page/accommodation/ImportEdit.vue'
export default {
  name: "roomManage",
  components: {EditRoomDialog, SearchHead, CustomTables,ImportEdit},
  data() {
    return {
      dialogTitle: "新增房间管理",
      showDialog: false,
      total: 0,
      tableData: [],
      searchForm: {
        pageNum: 1,
        pageSize: 10,
      },
      item: {},
      areaParentList: [],
      hotelList:[],
      isDetail: false,
      showDialog2:false,
      dialogTitle2:false,
      item2:{}
    };
  },
  mounted() {
    this.getInfo();
    this.getHotelInfo()
   
  },
  methods: {
    getHotelInfo(){
      accommodation.getHotelList().then(res =>{
        this.hotelList = res.data
      })
    },
    getInfo() {
      accommodation.getRoomByPage(this.searchForm).then((res) => {
        this.total = res.data.total;
        let data = res.data.records
        this.tableData = []
        data.forEach(item =>{
          this.tableData.push({
            ...item,
            roomTypeName: roomTypeObj[item.roomType],
            roomStatusName: roomStatusObj[item.roomStatus]
          })
        })

      });
    },
    handleCurrentChange(pageIndex) {
      this.searchForm.pageNum = pageIndex;
      this.getInfo();
    },
    handleSizeChange(pageSize) {
      this.searchForm.pageNum = 1;
      this.searchForm.pageSize = pageSize;
      this.getInfo();
    },
    editDone() {
      // 编辑成功返回
      this.showDialog = false;
      this.showDialog2 = false;
      this.getInfo();
    },
    headReset() {
      this.searchForm = {
        pageNum: 1,
        pageSize: 10,
      };
      this.getInfo();
    },
    headSearch() {
      this.searchForm.pageNum = 1;
      this.searchForm.pageSize = 10;
      this.getInfo();
    },
    tableDel() {
    },
    //导出
    tableExport() {
      accommodation.exportRoom(this.searchForm).then((res) => {
        downloadFile(res)
      }).catch(err => {
        console.log(err, 'err');
      })
    },
    // tableGetImport(){
    //   basic.importRoomTemp().then(res => {
    //     downloadFile(res)
    //   })
    // },
    tableAdd() {
      this.item = {
        roomBedUse:0
      };
      this.isDetail = false
      this.dialogTitle = "新增房间管理";
      this.showDialog = true;
    },
    tableGetImport() {
      basic.importRoomTemp().then(res => {
        downloadFile(res)
      })
    },
    tableImport(file){
      if (!/\.(xlsx|xls|XLSX|XLS)$/.test(file.name)) {
        this.$message({
          message: '请上传Excel只能为xlsx、xls格式',
          type: "warning",
        });
        return false;
      }
     
      let param ={
        file:file.raw
      }
      console.log(param,'param');
      basic.redis(param).then(res =>{
        console.log(res);
        this.redisLists()
        this.showDialog2 =true
        this.dialogTitle2='房间导入'

      })
    },
    redisLists(){
      basic.redisList().then(res =>{
        console.log(res,'00');
       this.item2 = res.data

      })
    },
    selectionChange(val) {
      console.log(val);
    },
    editItem(item) {
      this.item = item;
      this.isDetail = true
      this.dialogTitle = "房间管理详情";
      this.showDialog = true;
    },
    delItem(item) {
      this.$confirm(`此操作将永久删除该房间信息, 是否继续?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        accommodation.delRoom({roomId: item.roomId}).then((res) => {
          this.$message({
            message: "删除成功",
            type: "success",
          });
          this.getInfo();
        });
      }).catch(() => {
        this.$message({
          type: "info",
          message: "已取消删除",
        });
      });
    },
  },
};
</script>

<style scoped></style>
