<template>
  <custom-dialog :visible.sync="visible" :title="title" @close="colseDialog" @submit="submit">
    <el-form :model="editForm" label-width="80px" :rules="rules"  ref="editHotelForm" >
      <el-row :gutter="40">
        <el-col :span="12">
          <el-form-item label="所属酒店" prop="hotelId">
            <el-select v-model="editForm.hotelId" size="small" :disabled="disabled" class="block-select" placeholder="请选择所属酒店">
              <el-option
                  v-for="item in hotelList"
                  :key="item.hotelId"
                  :label="item.hotelName"
                  :value="item.hotelId">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="房间楼层" prop="roomFloor">
            <el-input size="small" v-model="editForm.roomFloor" :disabled="disabled" placeholder="请输入房间楼层"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="房间编号" prop="roomNo">
            <el-input size="small" v-model="editForm.roomNo" :disabled="disabled" placeholder="请输入房间编号"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="床位数" prop="roomBedCount">
            <el-input size="small" v-model="editForm.roomBedCount" :disabled="disabled" placeholder="请输入床位数"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="房间类型" prop="roomType">
            <el-select v-model="editForm.roomType" size="small" :disabled="disabled" class="block-select" placeholder="请选择房间类型">
              <el-option
                  v-for="item in roomTypeList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="已分配数" prop="roomBedUse">
            <el-input size="small" v-model="editForm.roomBedUse" :disabled="disabled" placeholder="请输入已分配数"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="是否正式" prop="roomBedUse">
            <el-select v-model="editForm.isFormal" size="small" :disabled="disabled" class="block-select" placeholder="请选择是否为正式房间">
              <el-option
                  v-for="item in  isFormalList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-form-item label="备注" prop="roomNote">
        <el-input size="small"  type="textarea" v-model="editForm.roomNote" :disabled="disabled" placeholder="请输入备注"></el-input>
      </el-form-item>
    </el-form>

  </custom-dialog>
</template>

<script>
import CustomDialog from "../../common/CustomDialog";
import accommodation from "../../../api/modules/accommodation";
import {roomTypeList} from "../../../utils/hotelType"
export default {
  name: "EditRoomDialog",
  components: {CustomDialog},
  props: ['visible', 'title', 'item', 'disabled'],
  data(){
    return{
      editForm:{
        roomBedUse:0
      },
      rules:{},
      roomTypeList:roomTypeList,
      hotelList:[],
      isFormalList:[{label:'是',value:0},{label:'否',value:1}]
    }
  },
  mounted() {
    this.getHotelInfo()
  },
  watch:{
    item(newVal,oldVal){
      this.editForm = {...newVal}
    }
  },
  methods:{
    getHotelInfo(){
      accommodation.getHotelList().then(res =>{
        this.hotelList = res.data
      })
    },
    colseDialog(){
      this.$emit('close')
    },
    submit(){
      this.$refs.editHotelForm.validate((valid) => {
        if(valid){
          if(this.editForm.roomId){
            // 木有编辑
            this.$emit('done')
          }else {
            accommodation.addRoom(this.editForm).then(res =>{
              this.$message({
                message: '新增成功',
                type: 'success'
              });
              this.$emit('done')
            })
          }
        }
      })
    },
  }
}
</script>

<style scoped>

</style>